import { Routes, Route } from "react-router-dom";

import { Layout } from "@gamate/components/layout";

import { routes } from "./tools/routes";
import TierListPage from "./pages/TierList";
import { config } from "./tools/config";

import "./App.css";

export default function App() {
  return (
    <>
      <Layout config={config}>
        <Routes>
          <Route path={routes.TierList} element={<TierListPage />} />
        </Routes>
      </Layout>
    </>
  );
}
