import { config } from "../config";

export const heroService = {
  getAll: async () => {
    const heroesJson = await fetch(
      `${config.API_URL}/heroes.json`
    )
      .then((resp) => resp.json())
      .catch(() => []);
    return heroesJson;
  }
};