import { useState, useEffect } from "react";
import styled from "styled-components";

import { TierListPage } from "@gamate/components";

import { heroService, tierlistService } from "../../tools/services";

const Container = styled.div`
  margin-top: 10px;
`;

const selectList = (list: any) => {
  list.isSelected = !list.isDisabled;
};

export default function LolTierListPage() {
  const [lists, setLists] = useState([] as any[]);
  const [heroes, setHeroes] = useState([] as string[]);

  useEffect(() => {
    (async function () {
      const tierlists = await tierlistService.getAll();
      tierlists.forEach(selectList);
      setLists(tierlists);

      const heroNames: string[] = (await heroService.getAll()).map((h: any) => h.name);
      setHeroes(heroNames);
    })();
  }, []);

  return (
    <Container>
      <TierListPage
        tierLists={lists}
        heroes={heroes}
        roles={["TOP", "MID", "BOT", "JUNGLE", "SUPPORT"]}
      />
    </Container>
  );
}
